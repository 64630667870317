/* styles.css */
@viewport {
  width: device-width;
  initial-scale: 1;
}

:root {
    --tomato: #D06047;
    --ochre: #C99528;
    --sage: #A4A37A;
    --amethyst: #766C8A;
    --clay: #D9A27F;
    --lavender: #AFA5BE;
    --sun: #F3EE8D;
    --black: #0d0d0d;
    --cream: #F4F0E7;
    --grey: rgb(34, 33, 33);
    --amethyst_bright: #4516a2;
}



.quiz-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width:  700px;
    margin: auto;
    min-height: 100vh; /* Use viewport height to ensure full height coverage */
    text-align: center; /* Center text for children */
  }
  
  /* Ensure the body and html cover the full height of the viewport */
  html, body {
    height: 100%;
    margin: 0;
  }

body {
    background-color: var(--cream);
    color: var(--black);
    font-family: 'Messina Sans', sans-serif;
        align-items: center;
}

.quiz-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.quiz-title {
    color: var(--grey);
    font-family: 'Garamond', serif;
    display: block;
    text-align: center;
}

.quiz-subtitle {
    color: var(--sage);
    display:block;
    text-align: center;
}



.quiz-start-btn {
    background-color: var(--amethyst_bright);
    color: var(--cream);
    font-size: 1.25rem;
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    border: 0; 
    text-transform: uppercase;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;

}

.quiz-start-btn:hover {
    background-color: var(--sun);
    color: var(--black); 
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .quiz-title {
        font-size: 1.5rem;
    }

    .quiz-start-btn {
        padding: 10px 20px;
    }
}


.fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.5s ease-out forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  

/* Additions to styles.css for Question and Results components */



.question-title {
    color: var(--amethyst);
    font-family: 'Garamond', serif;
    font-size: 1.8rem;
}

.question-prompt {
    margin: 20px 0;
    font-family: 'Garamond', serif;
    font-weight: bold;
    /*color: var(--clay);*/
    color: #0d0d0d;
    font-size: 3vw;
}

/* Example with viewport width for a more responsive design */
@media (max-width: 768px) {
    .question-prompt {
      font-size: 4vw; /* Adjusts size based on viewport width */
    }
  }
  

.answer-btn {
    background-color: var(--sage);
    color: var(--cream);
    margin: 10px; /* Increase margin for better spacing */
    padding: 10px 15px; /* Increase padding for a larger button size */
    font-size: 1.2rem; /* Increase font size for better readability */
    border: none;
    border-radius: 5px; /* Optional: add a border-radius for rounded corners */
    cursor: pointer; /* Ensures the cursor changes to a pointer on hover, indicating it's clickable */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for visual feedback */
    justify-content: flex-start;
}

.answer-btn:hover {
    background-color: var(--lavender);
    color: var(--black);
    transform: scale(1.05); /* Slightly enlarge button on hover/focus for interactive feedback */
    outline: none; /* Removes the outline to keep the design clean */
    
}


@media (max-width: 768px) {
    .answer-btn {
      padding: 8px 16px; /* Slightly smaller padding on mobile devices */
      font-size: 1rem; /* Adjust font size for smaller screens */
      margin: 8px; /* Adjust margin for compact spacing on smaller screens */
    }
  }


  .value-descriptions {
    display: flex;
    justify-content: space-between; /* Adjust space between min and max value descriptions */
    margin-top: 20px; /* Space between answer buttons and descriptions */
    width: 100%;
  }
  
  .min-value, .max-value {
    flex: 1; /* Allows each div to take up equal space */
    /* Adjustable margins for spacing around the descriptions */
    margin-left: 30px; /* Adjust this value to increase/decrease left margin */
    margin-right: 30px; /* Adjust this value to increase/decrease right margin */
  }
  
  @media (max-width: 768px) {
    .value-descriptions {
      flex-direction: column; /* Stack them on smaller screens */
      align-items: center;
    }
  
    .min-value, .max-value {
      margin: 10px 0; /* Adjust margin for vertical layout on smaller screens */
    }
  }

.results-container {
    background-color: var(--cream);
    color: var(--black);
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-x: hidden;
}

.results-content {
    width: 100%;
    max-width: 800px;
    padding: 0 10px;
    box-sizing: border-box;
    margin: 0 auto;
}

.big-chart-title {
  margin-top: 10px;
  font-family: 'Garamond', serif;
  font-size: medium;
  font-weight: bold;
}

.main-chart-container {
    width: 100%;
    max-width: 600px;
    min-width: 200px;
    margin: 0 auto;
    aspect-ratio: 1;
}

.small-charts-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 120px;
    gap: 20px;
    margin: 20px auto;
    flex-wrap: wrap;
}

.small-chart {
    flex: 1;
    min-width: 120px;
    max-width: 200px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .results-container {
        padding: 10px;
    }

    .small-charts-container {
        gap: 10px;
    }

    .small-chart {
        min-width: 100px;
        max-width: 150px;
    }

    .response-message {
        padding: 0 10px;
    }
}

@media (max-width: 480px) {
    .small-charts-container {
        flex-direction: column;
        align-items: center;
    }

    .small-chart {
        width: 80%;
        max-width: none;
    }
}

.chart-title {
    margin-top: 10px;
    font-family: 'Garamond', serif;
    font-size: small;
    font-weight: bold;
}

.results-title {
    color: var(--tomato);
    font-family: 'Garamond', serif;
}

.results-score {
    color: var(--ochre);
}

.response-message {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Garamond', serif;
}

.response-message-category {
  margin: 20px 0;
  justify-content: center;
  text-align: center;
  color: var(--amethyst);

}


.results-content.blur {
  filter: blur(100px);
}

.email-wall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it's above the results */
  backdrop-filter: blur(5px);
}

.email-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Adjust as needed */
}

.email-input-container {
  width: 100%;
  margin-bottom: 15px; /* Space between input and privacy notice */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.email-input-container label {
  width: 100%;
  margin-bottom: 10px;
  text-align: center; /* Center the label text */
}

.email-input-container input {
  flex-grow: 1;
  margin-right: 10px; /* Space between input and button */
}

.email-input-container button {
  white-space: nowrap;
}

.privacy-notice {
  font-size: 0.8em;
  color: #666;
  text-align: center;
  width: 100%;
}

.privacy-notice a {
  color: #0066cc;
  text-decoration: underline;
}

.role-options,
.leadership-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
}

.role-button {
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.role-button:hover {
  background-color: #f0f0f0;
  border-color: #999;
  transform: translateY(-1px);
}

.role-button:active {
  background-color: #e0e0e0;
  transform: translateY(0);
}

.checklist-item {
  margin: 0;
  line-height: 1.2;
  padding: 2px 0;  /* Small padding to prevent text from touching */
}

.assessment-thank-you {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Privacy Policy Styles */
.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  line-height: 1.6;
}

.privacy-policy-container h1 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.privacy-policy-container .last-updated {
  color: #666;
  font-style: italic;
  margin-bottom: 2rem;
}

.privacy-section {
  margin-bottom: 2rem;
}

.privacy-section h2 {
  color: #444;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.privacy-section p {
  margin-bottom: 1rem;
  color: #555;
}

.privacy-section ul {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.privacy-section li {
  margin-bottom: 0.5rem;
  color: #555;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 1rem;
  }
  
  .privacy-policy-container h1 {
    font-size: 2rem;
  }
  
  .privacy-section h2 {
    font-size: 1.5rem;
  }
}